import type { IconName } from '@st/ui/components/StIcon/types'

export function useLanguageSelect() {
  interface LanguageMap {
    id: string
    value: string
    icon: IconName
  }
  interface Lang {
    code: string
    name: string
  }
  const { locale, locales, defaultLocale } = useNuxtApp().$i18n

  function getLocaleRedirectPath(newLocale: string) {
    const url = new URL(window.location.href.replace(`/${locale.value}`, ''))
    url.searchParams.set('locale', newLocale)
    const localePrefix = newLocale === defaultLocale ? '' : `/${newLocale}`
    const targetPath = localePrefix + url.pathname + url.search + url.hash
    return targetPath
  }

  const selectedLanguage = computed({
    get: () => locale.value,
    set: (newLocale) => {
      window.location.href = getLocaleRedirectPath(newLocale)
    },
  })

  function getIconId(code: string) {
    const codeToIconMap: Record<string, IconName> = {
      ru: 'f-rus',
      en: 'f-gbr',
      es: 'f-esp',
      pt: 'f-prt',
    }
    return codeToIconMap[code]
  }

  const options = computed((): LanguageMap[] =>
    (locales.value as Lang[]).map((lang: Lang) => ({
      id: lang.code,
      value: lang.name,
      icon: getIconId(lang.code),
    })),
  )

  return {
    selectedLanguage,
    options,
  }
}
